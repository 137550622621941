import './Video.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import HeaderAdaptive from "../../Components/HeaderAdaptive/HeaderAdaptive";

function Video() {
    return (
        <div id='video'>
            <Header/>
            <HeaderAdaptive/>
            <br/><br/><br/><br/><br/>
            <h1 className='logo'>
                <a href='/'>REBECA LANE</a>
            </h1>
            <br/>
            <br/>

            <div className='video_content'>
                <iframe src="https://www.youtube.com/embed/atOg83Xig6I?si=pI6niYX6G0viiPB1&amp;controls=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <iframe src="https://www.youtube.com/embed/VbQ_yOlzWTs?si=Br6Ul7fnCcr5wlMT&amp;controls=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <iframe src="https://www.youtube.com/embed/P8Y0BB7kh2c?si=cIvCEhVBalv5bY-4&amp;controls=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <iframe src="https://www.youtube.com/embed/dT2mTKwXlG8?si=ILbrFtryz5ovwgrd&amp;controls=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <iframe src="https://www.youtube.com/embed/CJQTbHUm6cY?si=jDwExc565mVkbB94&amp;controls=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <iframe src="https://www.youtube.com/embed/xduSQrz70KI?si=3bHY_R31ErSZ6SIA&amp;controls=0"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <br/><br/><br/>
            <h1 className='logo'>
                <a href='/'>SPECIAL THANKS...</a>
            </h1>
            <div>
                <p className=''>Subscribe to my YouTube channel - <a
                    href='https://www.youtube.com/channel/UCdG7zFIa1LdzUdYO6LT4BHg'>Visit here</a></p>
                <p className=''>Chapin films for music video production - <a href='https://www.chapinfilms.com/'>Visit
                    chapin here</a></p>
                <p className=''>Newtopia as artist management - <a href='https://newtopia.it/'>Visit newtopia here</a>
                </p>
                <p className=''>Lenostube for youtube music video organic promotion - <a
                    href='https://www.lenostube.com/en/buy/youtube-music-video-promotion/'>Visit lenos here</a></p>

                <br/>

                <p className=''>Suscríbete a mi canal de YouTube - <a
                    href='https://www.youtube.com/channel/UCdG7zFIa1LdzUdYO6LT4BHg'>ir aquí</a></p>
                <p className=''>Chapin films para la producción de mí vídeos musicales - <a
                    href='https://www.chapinfilms.com/'>Visite chapin aquí</a></p>
                <p className=''>Lenostube para la promoción de mí vídeos musicales en youtube - <a
                    href='https://www.lenostube.com/en/buy/youtube-music-video-promotion/'>Visite lenos aquí</a></p>
            </div>
            <br/><br/><br/><br/><br/><br/>
            <Footer/>
            <br/><br/><br/>
        </div>
    )
}

export default Video;