import './HeaderAdaptive.css'
import React, {useState} from "react";
import burger from './burger-menu.png'
import close from './close.png'
import {NavLink} from "react-router-dom";

function HeaderAdaptive() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header>
            <h1 className='header_h1'>
                REBECA LANE
            </h1>
            <div className="burger-menu-container">
                {!isOpen && (<button className="burger-menu-button" onClick={toggleMenu}>
                    <img src={burger}/>
                </button>)}

                {isOpen && (<>
                        <button className='close' onClick={toggleMenu}>
                            <img src={close}/>
                        </button>
                        <div className="menu-items-container">
                            <ul>
                                <li><NavLink exact to="/news">News/Noticias</NavLink></li>
                                <li><NavLink exact to="/about">About</NavLink></li>
                                <li><NavLink exact to="/contact">Contact/Booking</NavLink></li>
                                <li><NavLink exact to="/videos">Videos</NavLink></li>
                                <li><NavLink exact to="/writing">Academic Writings/Textos Academicos</NavLink></li>
                                <li><NavLink exact
                                             to="https://mujeresdebolsagrande.blogspot.com">Poetry/Poesía</NavLink></li>
                                {/*<li><NavLink exact to="/press">Press</NavLink></li>*/}
                            </ul>
                            <footer>
                                <a href="https://twitter.com/rebecalane6" className="social-link">
                                    Twitter
                                </a>
                                <a href="https://www.facebook.com/rebecalane6/ " className="social-link">
                                    Facebook
                                </a>
                                <a href="https://www.instagram.com/rebecalane6/" className="social-link">
                                    Instagram
                                </a>
                            </footer>
                        </div>
                        <div className='background'>
                        </div>
                    </>
                )}
            </div>
        </header>
    )
}

export default HeaderAdaptive;