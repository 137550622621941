import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

function Header() {
    return (
        <nav>
            <ul>
                <li><NavLink exact to="/news">News/Noticias</NavLink></li>
                <li><NavLink exact to="/about">About</NavLink></li>
                <li><NavLink exact to="/contact">Contact/Booking</NavLink></li>
                <li><NavLink exact to="/videos">Videos</NavLink></li>
                <li><NavLink exact to="/writing">Academic Writings/Textos Academicos</NavLink></li>
                <li><NavLink exact to="https://mujeresdebolsagrande.blogspot.com">Poetry/Poesía</NavLink></li>
                {/*<li><NavLink exact to="/press">Press</NavLink></li>*/}
            </ul>
        </nav>
    );
}

export default Header;
