import './Writings.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import HeaderAdaptive from "../../Components/HeaderAdaptive/HeaderAdaptive";
import img1 from '../../images/el_hiphop.png'
import img2 from '../../images/ensayo_poetizado.png'
import img3 from '../../images/la_danza_de_la_vida.png'
import img4 from '../../images/reflexiones.png'
import img5 from '../../images/reflexiones_sobre2.png'
function Writings() {
    return (
        <div id='writings'>
            <Header/>
            <HeaderAdaptive/>
            <br/><br/><br/><br/><br/>
            <h1 className='logo'>
                <a href='/'>REBECA LANE</a>
            </h1>
            <br/>
            <br/>

            <div className='writings_container'>
                <div className="prediv1">
                    <a href='http://www.academia.edu/1088640/El_Hip_Hop_en_Guatemala_desde_la_perspectiva_de_los_Estudios_Culturales'>
                    <img src={img1}/>
                    <p className='px'>
                        El Hip Hop en Guatemala desde la perspectiva de los Estudios Culturales
                    </p></a>
                </div>
                <div className="prediv2">
                    <a href='https://www.academia.edu/1114888/Reflexiones_sobre_la_cultura_y_subtexto_de_mi_conciencia_sociol%C3%B3gica'>
                    <img src={img4}/>
                    <p className='px'>
                        Reflexiones sobre la cultura y subtexto de mi conciencia sociológica
                    </p></a>
                </div>
                <div className="prediv3">
                    <a href='https://www.academia.edu/2301510/La_Danza_de_la_Vida._Reflexiones_Sentipensantes'>
                    <img src={img3}/>
                    <p className='px'>
                        LA DANZA DE LA VIDA<br/>
                        reflexiones sentipensantes
                    </p></a>
                </div>
                <div className="prediv4">
                    <a href='https://www.academia.edu/1228291/Ensayo_Poetizado_El_proceso_judicial_a_Efra%C3%ADn_R%C3%ADos_Montt_por_Genocidio'>
                    <img src={img2}/>
                    <p className='px'>
                        El proceso judicial a Efraín Ríos Montt por Genocidio<br/>Ensayo poetizado <br/>
                    </p></a>

                </div>
                <div className="prediv5">
                    <a href='https://www.academia.edu/1154284/La_Culpa_arma_de_contenci%C3%B3n_masiva'>
                    <img src={img5}/>
                    <p className='px'>
                        Reflexiones sobre la cultura y subtexto de mi conciencia sociológicaII PARTE
                    </p></a>
                </div>
            </div>

            <br/>
            <br/><br/><br/><br/><br/><br/>
            <Footer/>
            <br/><br/><br/>
        </div>
    )
}

export default Writings;