import './Contact.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import HeaderAdaptive from "../../Components/HeaderAdaptive/HeaderAdaptive";

function Contact() {
    return (
        <div id='contact'>
            <Header/>
            <HeaderAdaptive/>
            <br/><br/><br/><br/><br/>
            <h1 className='logo'>
                <a href='/'>REBECA LANE</a>
            </h1>
            <br/>
            <br/>
            <div className='contact_text'>
                <p className='black'>
                    Contact
                </p>
                <p className='gray'>
                    If you need information, rates want to book a show or just want to say hello please drop me a line!
                </p>
                <p className='gray'>
                    Si necesitas información, tarifas, quieres organizar un concierto o si solo quieres saludar mándame
                    un
                    mensaje!
                </p>
                <br/>
                <br/>
                <br/>
                <div className='contact_form'>
                    <div>
                        <input placeholder='Name'></input>
                        <input placeholder='Email'></input>
                    </div>
                    <textarea rows='6' placeholder='Message'></textarea>
                </div>
                <br/>
                <a href='/' className='send_button'>
                    Send Message
                </a>
            </div>
            <br/>

            <br/><br/><br/><br/><br/><br/>
            <Footer/>
            <br/><br/><br/>
        </div>
    )
}

export default Contact;