import './News.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import HeaderAdaptive from "../../Components/HeaderAdaptive/HeaderAdaptive";

function News(){
    return(
        <div id='news'>
            <Header/>
            <HeaderAdaptive/>
            <br/><br/><br/><br/><br/>
            <h1 className='logo'>
                <a href='/'>REBECA LANE</a>
            </h1>
            <br/><br/>
            <iframe src="https://www.youtube.com/embed/atOg83Xig6I?si=bLq428IVJk2JVnnj&amp;controls=0"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
            </iframe>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Footer/>
            <br/><br/><br/>
        </div>
    )
}

export default News;