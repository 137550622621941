import './Footer.css'

function Footer(){
    return(
        <>
            <footer>
                <a href="https://twitter.com/rebecalane6" className="social-link">
                    Twitter
                </a>
                <a href="https://www.facebook.com/rebecalane6/ " className="social-link">
                    Facebook
                </a>
                <a href="https://www.instagram.com/rebecalane6/" className="social-link">
                    Instagram
                </a>
            </footer>
            <p className='footer'>Copyright © All rights reserved. Rebeca Lane 2016 ©</p>
        </>
    )
}

export default Footer;