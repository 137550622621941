import './About.css'
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import HeaderAdaptive from "../../Components/HeaderAdaptive/HeaderAdaptive";

function About() {
    return (
        <div id='about'>
            <Header/>
            <HeaderAdaptive/>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <h1 className='logo'>
                <a href='/'>REBECA LANE</a>
            </h1>
            <br/><br/>
            <div className='about_content'>
                <p className='black'>Biography/Bio</p>
                <br/>
                <br/>
                <p className='black'>English</p>
                <p className='gray'>Rebeca Eunice Vargas (Rebeca Lane) was born in Guatemala City on december 6th 1984.
                    She was born in the middle of a civil war and was named after her aunt that had been kidnaped and
                    disappeared by the military government in 1981 because of her political involvement in the guerrilla
                    movement. Since she was very young she started to investigate and get involved in the efforts to
                    retrieve the historical memory of those war years and became an activist in organizations of family
                    member of people kidnaped or killed by the military government. This brought her to get to know
                    other struggles of the people claiming their historical right over their lands and several political
                    movements of workers and land laborers against neoliberalism and the politics that had caused even
                    more social conflict that during the war. Being part of the social movement made her realize that
                    women had less participation and leadership, so she started to get interested in feminism. That took
                    her to other groups of women working through art, specifically theater and music. She took part of
                    “TrasBastidores” and was part of performances and theater plays that speak out for women’s rights
                    such as “Las Profanas” (2009), “El Juego” (2009) and most recently “Histéricas y no tanto” a Hip Hop
                    musical and cabaret in 2013. Theater has always been part of her life, she currently forms part of a
                    theater and Hip Hop crew that created “La Eskina” (2014) where they approach through graffiti, rap,
                    breakdance, deejaying, and parkour, the violence against youth in marginalized regions of the city.
                    As a poet she’s been committed to her liberation as a woman and the heterosexual roles imposed on
                    her body, and the colonization and militarization of the land she’s been born in. She’s been
                    published in several magazines in Guatemala, Puerto Rico and México, and keeps her words in her blog
                    “Mujeres de bolsa grande y zapatos bajos”. Poetry lead her to spoken-word and as a collaborator in
                    Da-Radio (A Hip Hop online radio) started to be part of Última Dosis a Hip Hop collective. She
                    started to record RAP songs since 2012 as a poetry exercise without foreseeing it would make her a
                    referent of the local scene. She started to sing with Última Dosis and also as a soloist. In October
                    2013 her EP “Canto” was released and started to tour Central America and México. She’s been part of
                    important festivals such as “Revolución Hip Hop 2012”, “Festival Ixchel 2012”, “Festival Rap Solo
                    2013”, “Festival de Juventud 2014” amongst others in Guatemala. In México she’s been part of
                    “Lesbianarte” both in 2013 and 2014, “1er Festival Internacional de Hip Hop 2013” in Querétaro,
                    “Encuentro Nacional Feminista 2013” in Guadalajara, “VIII Festival de Diversidad Sexual” in
                    Zacatecas. She’s also been part of festivals and seminars in Central America regarding human rights
                    and feminism, as well as in the Hip Hop culture and events of women like “Hall of Femme 2014” in
                    Nicaragua, and “I Festival de Hip Hop Femenino 2014” in Honduras. She’s founder of the movement
                    “Somos Guerreras” that’s making efforts to create a women community into the Hip Hop Culture to
                    transform the relations on it to make them equal and non sexist. She’s also doing rap workshops with
                    women and young people using feminist methodology so that poetry can become a way of expressing the
                    struggles embodied against oppression. Her rap lyrics are about her experience in a female body and
                    the struggle of women against sexism. She also speaks out about social problems in guatemalan
                    society such as the consequences of war and the lack of justice. In 2014 she won the first place in
                    the contest “Proyecto L” that was looking for music that enforces freedom of speech with the song
                    “Cumbia de la Memoria”. In this song she discusses the genocide perpetrated by military government
                    during the war.</p>
                <br/>
                <p className='black'>Español</p>
                <p className='gray'>Rebeca Eunice Vargas (Rebeca Lane) nació en la Ciudad de Guatemala el 6 de diciembre
                    de 1984. Nació en medio de una guerra y fue nombrada en honor a su tía que fue secuestrada y
                    desaparecida por el gobierno militar en 1981 debido a su militancia política en la guerrilla.Desde
                    muy joven empezó a investigar e involucrarse en el trabajo de recuperación de la memoria histórica y
                    se hizo activista en organizaciones de familiares de personas desaparecidas o asesinadas por el
                    ejército. Esto la involucró en otras luchas como la histórica lucha por las tierras de pueblos
                    indígenas, y movimientos políticos de obreros y pobladores en contra del neoliberalismo y sus
                    políticas que han causado incluso más conflictividad que durante la guerra.Siendo parte de los
                    movimientos sociales se dio cuenta que las mujeres tenían menos participación y liderazgo por lo que
                    empezó a interesarse en el feminismo. Eso la llevó a involucrarse en grupos de mujeres trabajando a
                    través del arte, específicamente teatro y música. Formó parte del grupo “TrasBastidores” y fue parte
                    del elenco de obras de teatro y performances que abordan los derechos de las mujeres como “Las
                    Profanas” (2009), “El Juego” (2009), y más recientemente “Histéricas y no tanto”, un musical de Hip
                    Hop y cabaret en 2013.El teatro siempre ha sido parte de su vida, en el 2014 formó parte del grupo
                    que montó la obra de teatro y Hip Hop “La Eskina” donde a través del graffiti, rap,breakdance,
                    deejaying y parkour se aborda el tema de la violencia contra la juventud marginalizada en la
                    ciudad.Como poeta se ha comprometido a su liberación como mujer y la heterosexualidad obligatoria,
                    así como la colonización y militarización en su país. Ha publicado poesía en varias revistas en
                    Guatemala, Puerto Rico y México. Actualiza con sus letras el blog “Mujeres de bolsa grande y zapatos
                    bajos”.La poesía la llevó al spoken-word (palabra hablada) y como colaboradora en Da-Radio (radio
                    online de Hip Hop) se hizo parte del colectivo Última Dosis. Con ellos comenzó a grabar rap desde el
                    año 2012, y empezó a cantar tanto con el grupo como solista. En octubre de 2013 su primer EP “Canto”
                    fue lanzando y realizó un tour para presentarlo en Centroamérica y México.Ha sido parte de
                    importantes festivales como “Revolución Hip Hop 2012”, “Festival Ixchel 2012”, “Festival Rap Solo
                    2013”, “Festival de Juventud 2014” entro otros en Guatemala. En México ha sido parte del festival de
                    arte lésbico y feminista “Lesbianarte” tanto en el 2013 como en el 2014, también el “1er Festival
                    Internacional de Hip Hop 2013” en Querétaro, el “Encuentro Nacional Feminista 2013” en Guadalajara,
                    el “VIII Festival de Diversidad Sexual” in Zacatecas.También ha sido parte de festivales y
                    seminarios en Centroamérica alrededor de derechos humanos y feminismo, así como dentro de la cultura
                    Hip Hop en eventos de mujeres como el “Hall of Femme 2014” en Nicaragua, el “I Festival de Hip Hop
                    Femenino 2014” in Honduras, y el “Festival Union Break 2015” en Costa Rica.Es fundadora del
                    movimiento “Somos Guerreras” a partir del cual se están creando espacios de mujeres dentro de la
                    cultura Hip Hop para transformar las relaciones dentro de la misma hacia unas más igualitarias y
                    menos sexistas. Como parte de este movimiento también imparte talleres de rap, de oralidad poética,
                    y escritura creativa con mujeres y jóvenes usando metodología feminista para que la poesía se
                    convierta en una manera de expresar las luchas en el cuerpo en contra de las opresiones.Sus letras
                    de rap son acerca de su experiencia en un cuerpo de mujer y la lucha de las mujeres en contra del
                    machismo. Ha realizado temas para campañas feministas como “Ni encerradas ni con miedos” de Radio
                    Progreso en Honduras, y “¿Vos cómo haces el amor?” para el Programa Feminista La Corriente en
                    Nicaragua.También habla acerca de problemas sociales en la sociedad guatemalteca, tales como las
                    consecuencias de la guerra y la falta de justicia. En el 2014 ganó el primer lugar en el concurso
                    “Proyecto L” que buscaba música que apoyara la libertad de expresión, con la canción “Cumbia de la
                    Memoria”.Sigue trabajando como socióloga con publicaciones y ponencias acerca de culturas urbanas e
                    identidades juveniles, y más recientemente sobre educación y su rol en la reproducción de la
                    inequidad social.</p>
            </div>
            <br/><br/><br/>
            <br/><br/><br/>
            <Footer/>
            <br/><br/><br/>
        </div>
    )
}

export default About;