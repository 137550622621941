import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';

import About from "./Pages/about/About";
import News from "./Pages/news/News";
import Contact from "./Pages/contact/Contact";
import Video from "./Pages/videos/Video";
import Writings from "./Pages/academic-writings/Writings";
import Press from "./Pages/press/Press";

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<News/>} />
                <Route path='/news' element={<News/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/videos' element={<Video/>} />
                <Route path='/writing' element={<Writings/>} />
                <Route path='/poesia' element={<About/>} />
                {/*<Route path='/press' element={<Press/>} />*/}
            </Routes>
        </Router>
    );
}

export default App;
